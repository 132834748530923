<template>
  <div>
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.username }}
            <!-- {{ userData.fullName || userData.username }} -->
          </p>
          <!-- <span class="user-status">{{ userData.role }}</span> -->
        </div>
        <b-avatar
          size="40"
          :src="userData.avatar"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon
            v-if="!userData.fullName"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </template>
      <b-dropdown-item
        id="change-password"
        link-class="d-flex align-items-center"
        @click="changePassword"
      >
        <feather-icon
          size="16"
          icon="LockIcon"
          class="mr-50"
        />
        <span>Change Password</span>
      </b-dropdown-item>
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Logout</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <!-- modal -->
    <b-modal
      ref="ad-deposit-modal"
      v-model="modalShow"
      title="Change Password"
      ok-title="Confirm"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        class="auth-login-form mt-2"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          :state="oldPasswordState"
          class="input-group-merge"
          invalid-feedback="oldPassword is required"
        >
          <div class="d-flex justify-content-between">
            <label for="oldPassword-input">Old Password</label>
          </div>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="oldPassword-input"
              ref="oldPassword"
              v-model="params.oldPassword"
              :state="oldPasswordState"
              class="form-control-merge"
              autocomplete="off"
              :type="type1"
              required
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon1"
                @click="type1 = type1 === 'password' ? 'text' : 'password'"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          :state="passwordState"
          class="input-group-merge"
          invalid-feedback="password is required"
        >
          <div class="d-flex justify-content-between">
            <label for="password-input">Password</label>
          </div>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="password-input"
              ref="password"
              v-model="params.password"
              :state="passwordState"
              class="form-control-merge"
              autocomplete="off"
              :type="type2"
              required
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon2"
                @click="type2 = type2 === 'password' ? 'text' : 'password'"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          :state="confirmPasswordState"
          class="input-group-merge"
          invalid-feedback="confirmPassword is required"
        >
          <div class="d-flex justify-content-between">
            <label for="confirmPassword-input">Confirm Password</label>
          </div>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="confirmPassword-input"
              ref="confirmPassword"
              v-model="params.confirmPassword"
              :state="confirmPasswordState"
              class="form-control-merge"
              autocomplete="off"
              :type="type3"
              required
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon3"
                @click="type3 = type3 === 'password' ? 'text' : 'password'"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BModal,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { showToast } from '@/libs/utils'
// import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BModal,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    BInputGroup,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      params: {
        oldPassword: '',
        password: '',
        confirmPassword: '',
      },
      oldPasswordState: null,
      passwordState: null,
      confirmPasswordState: null,
      modalShow: false,
      userData: JSON.parse(localStorage.getItem('userData')) || {},
      type1: 'password',
      type2: 'password',
      type3: 'password',
      avatarText,
    }
  },
  computed: {
    passwordToggleIcon1() {
      return this.type1 === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIcon2() {
      return this.type2 === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIcon3() {
      return this.type3 === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    logout() {
      if (window.location.href.includes('8080')) {
        localStorage.clear()
        this.$router.push({ name: 'auth-login' })
        return
      }

      this.$showLoading()
      this.$http.post('/api/v1/admin/logout').then(response => {
        this.$hideLoading()
        if (response.data.code == 200) {
          // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')

          // Remove userData from localStorage
          localStorage.removeItem('userData')
          localStorage.removeItem('currentTime')
          localStorage.removeItem('merchantNo')
          localStorage.removeItem('loginInfo')
          localStorage.removeItem('uuid')
          localStorage.removeItem('currentTimeZone')
          localStorage.removeItem('loglevel:webpack-dev-server')
          localStorage.removeItem('token')
          localStorage.removeItem('navMenuItems')
          localStorage.removeItem('permissions')
          localStorage.removeItem('defaultRouteName')

          // Reset ability
          this.$ability.update(initialAbility)

          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          })
        }
      })
    },
    changePassword() {
      this.modalShow = true
    },
    resetModal() {
      this.params = {
        oldPassword: '',
        password: '',
        confirmPassword: '',
      }
      this.oldPasswordState = null
      this.passwordState = null
      this.confirmPasswordState = null
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      this.$showLoading()
      this.$http
        .post('/api/v1/admin/resetPassword', this.params)
        .then(response => {
          this.$hideLoading()
          if (response.data.code == 200) {
            showToast(this, 'success', response.data.message)
            this.logout()
            // this.$nextTick(() => {
            //   this.$refs["ad-deposit-modal"].toggle("#change-password");
            // });
          } else {
            showToast(this, 'warning', response.data.message)
          }
        })
        .catch(error => {
          this.$hideLoading()
          showToast(this, 'warning', error.message)
        })
    },
    checkFormValidity() {
      const oldPasswordValid = this.$refs.oldPassword.checkValidity()
      const passwordValid = this.$refs.password.checkValidity()
      const confirmPasswordValid = this.$refs.confirmPassword.checkValidity()
      this.oldPasswordState = oldPasswordValid
      this.passwordState = passwordValid
      this.confirmPasswordState = confirmPasswordValid
      if (oldPasswordValid && passwordValid && confirmPasswordValid) {
        return true
      }
      return false
    },
  },
}
</script>
<style>
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: 14rem !important;
}
</style>
